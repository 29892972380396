import styled from '@emotion/styled'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  name: string
  error?: boolean
  placeholder?: string
  required?: boolean
  value?: string
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
}

export const Textarea = memo(
  forwardRef<HTMLTextAreaElement, Props>(function Textarea(
    {
      name,
      error = false,
      placeholder,
      required,
      value,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        error={error}
        name={name}
        placeholder={placeholder}
        required={required}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />
    )
  }),
)
Textarea.displayName = 'Textarea'

const Container = styled.textarea<{ error: boolean }>`
  width: 100%;
  min-height: 10rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.variants.base};
  border: 1px solid ${({ theme }) => theme.colors.variants.base};
  margin-top: 2rem;
  padding: 1rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors.variants.base};
  }
`
