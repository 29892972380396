import axios, { AxiosError } from 'axios'
import { Either, left, right } from 'fp-ts/Either'

export interface FormData {
  name: string
  lastname?: string
  email: string
  website?: string
  message?: string
  privacy_policy: boolean
  newsletter_subscription?: boolean
  referrer: string
  user_ip?: string
}

export interface ContactsFormSenderBackendConfiguration {
  formURL: string
}

export type ContactsFormSenderBackend = (
  data: FormData,
) => Promise<Either<AxiosError, void>>

export const ContactsFormSenderBackend =
  ({
    formURL,
  }: ContactsFormSenderBackendConfiguration): ContactsFormSenderBackend =>
  async (data): Promise<Either<AxiosError, void>> => {
    try {
      await axios({
        method: 'POST',
        url: formURL,
        headers: {
          'Content-type': 'application/json',
        },
        data,
      })
      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }

export interface ContactsFormSenderSlackConfiguration {
  webhookURL: string
}

export type ContactsFormSenderSlack = (input: {
  data: FormData
  backendError?: AxiosError
}) => Promise<Either<AxiosError, void>>

export const ContactsFormSenderSlack =
  ({
    webhookURL,
  }: ContactsFormSenderSlackConfiguration): ContactsFormSenderSlack =>
  async ({ data, backendError }) => {
    const message = `*RICEVUTA NUOVA LEAD*
*Referrer:* ${data.referrer}

*Nome:* ${data.name}
*Cognome:* ${data.lastname}
*Email:* ${data.email}
*Sito web:* ${data.website}
*Messaggio:* ${data.message}
*Accettazione privacy:* ${data.privacy_policy}`

    const backendFeedBackMessage = backendError
      ? `
*Attenzione!!*
*Non è stato possibile salvare il messaggio sul CMS, né procedere all'invio di email.*
*QUESTA È L'UNICA TRACCIA DELLA LEAD RICEVUTA*

*Errore:* ${backendError.response?.status} ${backendError.name}
${JSON.stringify(backendError.response?.data, null, 2)}


  `
      : ''

    try {
      await axios({
        method: 'POST',
        url: webhookURL,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
        data: JSON.stringify({
          username: backendError ? '[ERROR] Contacts Form' : 'Contacts Form',
          text: [message, backendFeedBackMessage].join('\n\n'),
        }),
      })
      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }
