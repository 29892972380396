import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Checkbox'
import { FlexBox } from 'app/components/FlexBox'
import { FormMessages } from 'app/components/FormMessages'
import { Input } from 'app/components/Input'
import { Spinner } from 'app/components/Spinner'
import { Textarea } from 'app/components/Textarea'
import {
  ContactsFormSenderBackend,
  FormData,
} from 'app/utils/ContactsFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import axios from 'axios'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  languageCode: string
  postSendRedirectPath: string
  onContactsFormSendToBackend: ContactsFormSenderBackend
}

export const ContactsForm = memo(function ContactsForm({
  languageCode,
  postSendRedirectPath,
  onContactsFormSendToBackend,
}: Props) {
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [ipAddress, setIpAddress] = useState('')

  axios
    .get('https://geolocation-db.com/json/')
    .then((res) => {
      setIpAddress(res.data.IPv4)
    })
    .catch(function () {
      return false
    })

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onContactsFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        window.location.href = postSendRedirectPath
      }
    },
    [isSendingForm, onContactsFormSendToBackend, postSendRedirectPath],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      referrer: typeof window !== 'undefined' ? window.location.href : '',
      email:
        typeof window !== 'undefined'
          ? new URLSearchParams(window.location.search).get('email') ||
            undefined
          : undefined,
    },
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container dial={2} tag="section">
      <Title>{useVocabularyData('contact-us', languageCode)}</Title>
      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} />
        <Wrapper row wrap space="between">
          <Input type="hidden" {...register('referrer')} />
          <Input type="hidden" value={ipAddress} {...register('user_ip')} />
          <Input
            error={formState.errors.name !== undefined}
            isDirty={formState.dirtyFields.name}
            type="text"
            placeholder={`${useVocabularyData('first-name', languageCode)} *`}
            required={true}
            {...register('name', {
              required: true,
              validate: (name) => name.length > 2,
            })}
          />
          <Input
            error={formState.errors.lastname !== undefined}
            type="text"
            placeholder={useVocabularyData('last-name', languageCode)}
            {...register('lastname')}
          />
          <Input
            error={formState.errors.email !== undefined}
            isDirty={formState.dirtyFields.email}
            type="email"
            placeholder={`${useVocabularyData('email', languageCode)} *`}
            required={true}
            {...register('email', {
              required: true,
              validate: (email) => EMAIL_REGEX.test(email),
            })}
          />
          <Input
            error={formState.errors.website !== undefined}
            type="text"
            placeholder={useVocabularyData('website', languageCode)}
            {...register('website')}
          />
          <Textarea
            error={formState.errors.message !== undefined}
            placeholder={useVocabularyData('message', languageCode)}
            required={false}
            {...register('message')}
          />
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('data-protection', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
          <Checkbox
            label={useVocabularyData('sign-up-newsletter', languageCode)}
            {...register('newsletter_subscription')}
          />
          {displaySendErrorFeedback ? (
            <FormMessages
              text={useVocabularyData('form-error-message', languageCode)}
            />
          ) : null}
          <Input
            name="submit"
            type="submit"
            value={useVocabularyData('submit', languageCode)}
            variant="submit"
          />
        </Wrapper>
      </Form>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background-color: black;
  border-bottom: 1px solid white;
  padding: 5rem 1rem;
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.variants.primary};
  margin-bottom: 1rem;

  @media all and (max-width: 571px) {
    font-size: 1.8rem;
  }
`

const Form = styled.form`
  width: 100%;
  max-width: 1000px;
  position: relative;

  @media all and (max-width: 600px) {
    input,
    select,
    textarea {
      width: 100%;
      display: block;
      max-width: none;
    }
  }
`
const Wrapper = styled(FlexBox)``
